import { createMachine } from 'xstate';

import {
  displayDependentFields,
  saveStarterFiles,
  updateItemValues,
} from 'machines/shared/actions/actions';

import {
  assignMessage,
  selectObject,
  spawnObject,
  spawnDetailsMachines,
  updateDetailsWithStore,
  requestDetails,
  assignError,
  updateParent,
  removeChildObject,
  updateDetailConfig,
  replaceValues,
  initDetailReady,
  assignPdfKey,
  assignFileNames,
  assignCalcError,
  clearCalcError,
  createNewEntryConfigUpdate,
  spawnEditObject,
  executeCallback,
  assignCreatedObjectId,
  replaceDetailDraft,
  removeFile,
  addFileToDelete,
  addFileToTempFiles,
  removeFileFromTemp,
  assignBasePath,
  addSignature,
  assignSignaturePath,
  removePreviousSignature,
  renameFile,
  renderCalcMessage,
  renderErrorMessage,
  removeSignaturePath,
  resetFiles,
  renderFilesError,
  addSignatureToFiles,
  addUploadedFiles,
  finishCalculating,
  setCalculating,
  showSuccessNotify,
  showErrorNotify,
  replaceDetailStore,
  // removeTouchedFields,
} from './FormMachine.actions';
import {
  setDataService,
  calculateService,
  getAllPdfs,
  getDataUpdate,
  submitAfterUpdateService,
  uploadAllFiles,
  deleteAllFiles,
  submitFilesService,
  filesBasePath,
  signatureBasePath,
  uploadSignature,
  submitImgSignature,
} from './FormMachine.services';
import {
  checkIfReady,
  skipPdf,
  sameFiles,
  skipFiles,
  skipSignature,
  hasImgSignature,
  checkIfAbleToSave,
} from './FormMachine.utils';

export const createFormMachine = ({
  id,
  key = '',
  object,
  config = [],
  details = [],
  globalConfig = [],
  globalObjects = [],
  files = [],
  signature = null,
  parent = undefined,
  parentFieldId = '',
  callback = undefined,
}: any) => {
  // @ts-ignore
  return createMachine({
    id: `formMachine`,
    initial: 'editing',
    context: {
      key,
      parent,
      parentFieldId,
      object,
      config,
      details,
      globalObjects,
      globalConfig,
      error: undefined,
      message: undefined,
      calcError: undefined,
      formatedSteps: [],
      files,
      tempFiles: [],
      filesToDelete: [],
      filesBasePath: null,
      signature,
      signaturePath: '',
      callback,
      createdObjectId: '',
      selectedObjectId: id,
      childObjectRef: undefined,
      pdfOptions: { key: '', fileNames: [] },
      isCalculating: false,
    },
    entry: [saveStarterFiles, displayDependentFields, spawnDetailsMachines],
    states: {
      editing: {
        id: 'editing',
        // @ts-ignore
        on: {
          signature: {
            actions: [removePreviousSignature, addSignature],
          },
          renameFile: {
            actions: [renameFile],
          },
          removeFile: {
            actions: [removeFile, addFileToDelete],
          },
          removeFileFromTemp: {
            actions: removeFileFromTemp,
          },
          fileUpload: {
            actions: addFileToTempFiles,
          },
          selectObject: {
            actions: [selectObject],
          },
          UPDATE_VALUE: {
            target: 'calculating',
            actions: [updateItemValues, updateDetailConfig],
          },
          UPDATE_VALUE_WITHOUT_CALCULATING: {
            actions: [updateItemValues, updateDetailConfig],
          },
          UPDATE_BOOLEAN: {
            actions: [updateItemValues, updateDetailConfig],
          },
          CALCULATE: {
            target: 'calculating',
          },
          CALCULATE_DRAFT: {
            target: 'calculating.calcDraftGettingDetails',
          },
          SUBMIT: { target: 'submitting' },
          SAVE: { target: 'saving' },
        },
      },

      saving: {
        initial: 'calcDataGettingDetails',
        states: {
          calcDataGettingDetails: {
            exit: [initDetailReady],
            entry: [setCalculating, clearCalcError, requestDetails],
            always: [{ target: 'calculateData', cond: checkIfReady }],
            on: {
              detailsUpdate: { actions: [updateDetailsWithStore] },
            },
          },
          calcDraftGettingDetails: {
            exit: [initDetailReady],
            entry: [clearCalcError, requestDetails],
            always: [{ target: 'calculateDetailDraft', cond: checkIfReady }],
            on: {
              detailsUpdate: { actions: [updateDetailsWithStore] },
            },
          },
          calculateData: {
            // enable if you want calculate to always update fields
            // exit: removeTouchedFields,
            invoke: {
              id: 'calculateStore',
              src: calculateService('store'),
              onDone: {
                target: `gettingDetails`,
                actions: [
                  replaceValues,
                  replaceDetailStore,
                  renderCalcMessage,
                  finishCalculating,
                ],
              },
              onError: {
                target: `#editing`,
                actions: [assignCalcError, showErrorNotify, finishCalculating],
              },
            },
          },
          calculateDetailDraft: {
            entry: [clearCalcError],
            invoke: {
              id: 'calculateDraft',
              src: calculateService('draft'),
              onDone: {
                target: `#editing`,
                actions: [replaceValues, replaceDetailDraft, renderCalcMessage],
              },
              onError: {
                target: `#editing`,
                actions: [assignCalcError],
              },
            },
          },
          gettingDetails: {
            entry: requestDetails,
            always: {
              cond: checkIfAbleToSave,
              target: 'sendingData',
            },
            on: {
              detailsUpdate: {
                actions: updateDetailsWithStore,
              },
            },
          },
          // -------- Submitting ---------
          sendingData: {
            invoke: {
              id: 'setData',
              src: setDataService,
              onDone: {
                target: 'submittingAfterUpdate',
                actions: [
                  assignMessage,
                  updateParent,
                  assignPdfKey,
                  executeCallback,
                  assignCreatedObjectId,
                ],
              },
              onError: {
                target: '#failure',
                actions: assignError,
              },
            },
          },
          submittingAfterUpdate: {
            invoke: {
              src: submitAfterUpdateService,
              onDone: {
                target: 'gettingFilesBasePath',
                actions: [showSuccessNotify],
              },
              onError: {
                target: '#failure',
                actions: assignError,
              },
            },
          },

          // -------- Files -----------
          gettingFilesBasePath: {
            always: {
              cond: skipFiles,
              target: 'gettingPdfs',
            },
            invoke: {
              src: filesBasePath,
              onDone: {
                target: 'uploadingNewFiles',
                actions: assignBasePath,
              },
              onError: {
                // on error skip getting files
                target: 'deletingFiles',
                actions: renderErrorMessage('Getting file path'),
              },
            },
          },
          uploadingNewFiles: {
            invoke: {
              src: uploadAllFiles,
              onDone: {
                target: 'deletingFiles',
                actions: [renderFilesError('Uploading file'), addUploadedFiles],
              },
              onError: {
                target: 'deletingFiles',
                actions: renderFilesError('Uploading file'),
              },
            },
          },
          deletingFiles: {
            invoke: {
              src: deleteAllFiles,
              onDone: {
                target: 'submittingFiles',
                actions: [renderFilesError('Deleting File'), resetFiles],
              },
              onError: {
                target: 'submittingFiles',
                actions: renderFilesError('Deleting File'),
              },
            },
          },
          submittingFiles: {
            always: {
              cond: sameFiles,
              target: 'gettingPdfs',
            },
            invoke: {
              src: submitFilesService,
              onDone: {
                target: 'gettingPdfs',
              },
              onError: {
                target: '#failure',
                actions: assignError,
              },
            },
          },
          // ----------------------------
          gettingPdfs: {
            always: {
              cond: skipPdf,
              target: '#editing',
            },
            invoke: {
              src: getAllPdfs,
              onDone: {
                target: '#editing',
                actions: [assignFileNames],
              },
              onError: {
                target: '#failure',
                actions: assignError,
              },
            },
          },
        },
      },

      //@ts-ignore
      calculating: {
        initial: 'calcDataGettingDetails',
        states: {
          calcDataGettingDetails: {
            exit: [initDetailReady],
            //@ts-ignore
            entry: [setCalculating, clearCalcError, requestDetails],
            always: [{ target: 'calculateData', cond: checkIfReady }],
            on: {
              detailsUpdate: { actions: [updateDetailsWithStore] },
            },
          },
          calcDraftGettingDetails: {
            exit: [initDetailReady],
            // @ts-ignore
            entry: [clearCalcError, requestDetails],
            always: [{ target: 'calculateDetailDraft', cond: checkIfReady }],
            on: {
              detailsUpdate: { actions: [updateDetailsWithStore] },
            },
          },
          calculateData: {
            // enable if you want calculate to always update fields
            // exit: removeTouchedFields,
            entry: [clearCalcError, setCalculating],
            invoke: {
              id: 'calculateStore',
              src: calculateService('store'),
              onDone: {
                target: `#editing`,
                actions: [
                  replaceValues,
                  replaceDetailStore,
                  renderCalcMessage,
                  finishCalculating,
                ],
              },
              onError: {
                target: `#editing`,
                actions: [assignCalcError, finishCalculating],
              },
            },
          },
          calculateDetailDraft: {
            // @ts-ignore
            entry: [clearCalcError, setCalculating],
            invoke: {
              id: 'calculateDraft',
              src: calculateService('draft'),
              onDone: {
                target: `#editing`,
                actions: [
                  replaceValues,
                  replaceDetailDraft,
                  renderCalcMessage,
                  finishCalculating,
                ],
              },
              onError: {
                target: `#editing`,
                actions: [assignCalcError, finishCalculating],
              },
            },
          },
        },
        on: {
          UPDATE_VALUE: {
            target: '.calcDataGettingDetails',
            actions: [updateItemValues, updateDetailConfig],
          },
          UPDATE_BOOLEAN: {
            target: `.calcDataGettingDetails`,
            actions: [updateItemValues, updateDetailConfig],
          },
          CALCULATE: { target: 'calculating' },
          CALCULATE_DRAFT: { target: `calculating.calcDraftGettingDetails` },
          ABORT_CALCULATE: { target: `editing` },
          SAVE: { target: 'saving' },
        },
      },

      submitting: {
        id: 'submitState',
        initial: 'gettingDetails',
        states: {
          gettingDetails: {
            entry: requestDetails,
            always: {
              cond: checkIfReady,
              target: 'sendingData',
            },
            on: {
              detailsUpdate: {
                actions: updateDetailsWithStore,
              },
            },
          },
          // -------- Submitting ---------
          sendingData: {
            // @ts-ignore
            invoke: {
              id: 'setData',
              src: setDataService,
              onDone: {
                target: 'submittingAfterUpdate',
                actions: [
                  assignMessage,
                  updateParent,
                  assignPdfKey,
                  executeCallback,
                  assignCreatedObjectId,
                ],
              },
              onError: {
                target: '#failure',
                actions: assignError,
              },
            },
          },
          submittingAfterUpdate: {
            // @ts-ignore
            invoke: {
              src: submitAfterUpdateService,
              onDone: {
                target: 'gettingFilesBasePath',
              },
              onError: {
                target: '#failure',
                actions: assignError,
              },
            },
          },
          // -------- Files -----------
          gettingFilesBasePath: {
            always: {
              cond: skipFiles,
              target: 'gettingPdfs',
            },
            // @ts-ignore
            invoke: {
              src: filesBasePath,
              onDone: {
                target: 'uploadingNewFiles',
                actions: assignBasePath,
              },
              onError: {
                // on error skip getting files
                target: 'deletingFiles',
                actions: renderErrorMessage('Getting file path'),
              },
            },
          },
          uploadingNewFiles: {
            invoke: {
              src: uploadAllFiles,
              onDone: {
                target: 'deletingFiles',
                actions: [renderFilesError('Uploading file'), addUploadedFiles],
              },
              onError: {
                target: 'deletingFiles',
                actions: renderFilesError('Uploading file'),
              },
            },
          },
          deletingFiles: {
            invoke: {
              src: deleteAllFiles,
              onDone: {
                target: 'submittingFiles',
                actions: [renderFilesError('Deleting File'), resetFiles],
              },
              onError: {
                target: 'submittingFiles',
                actions: renderFilesError('Deleting File'),
              },
            },
          },
          submittingFiles: {
            always: {
              cond: sameFiles,
              target: 'gettingPdfs',
            },
            // @ts-ignore
            invoke: {
              src: submitFilesService,
              onDone: {
                target: 'gettingPdfs',
              },
              onError: {
                target: '#failure',
                actions: assignError,
              },
            },
          },
          // ----------------------------
          gettingPdfs: {
            always: {
              cond: skipPdf,
              target: '#final',
            },
            // @ts-ignore
            invoke: {
              src: getAllPdfs,
              onDone: {
                target: '#final',
                actions: assignFileNames,
              },
              onError: {
                target: '#failure',
                actions: assignError,
              },
            },
          },
          // -----------------
        },
      },
      updating: {
        invoke: {
          src: getDataUpdate,
          onDone: {
            target: 'editing',
            actions: createNewEntryConfigUpdate,
          },
          onError: { target: 'editing' },
        },
      },
      final: { id: 'final' },
      failure: {
        id: 'failure',
        on: {
          RETRY: '#submitState',
          STEP_CHANGE: 'editing',
        },
      },
    },
    //@ts-ignore
    on: {
      CHILD_UPDATE: {
        target: 'updating',
      },
      KILL_CHILD: {
        actions: removeChildObject,
      },
      SPAWN_OBJECT: {
        actions: spawnObject,
      },
      SPAWN_EDIT_OBJECT: {
        actions: spawnEditObject,
      },
    },
  });
};

import React, { FC, useMemo } from 'react';

import { GroupContainer } from '../Groups/Group.style';
import {
  Container,
  ContentContainer,
  Label,
  LabelContainer,
} from '../shared/ConfigBaseItem/ConfigBaseItem.style';

type Props = {
  address: string;
};

const Geolocation: FC<Props> = ({ address }) => {
  const formattedAddress = useMemo(() => {
    return address.replace(/ /g, '+');
  }, [address]);

  return (
    <GroupContainer>
      <Container>
        <LabelContainer>
          <Label>Προβολή χάρτη</Label>
        </LabelContainer>
        <ContentContainer>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${formattedAddress}`}
            target='blank'
            style={{ fontWeight: 700, color: 'black' }}
          >
            {address}
          </a>
        </ContentContainer>
      </Container>
    </GroupContainer>
  );
};

export default Geolocation;

import { useCallback, useEffect } from 'react';

import Modal from 'react-modal';
import { useBlocker } from 'react-router-dom';
import styled from 'styled-components';

import { CloseIcon, ModalMidScreen, ModalOverlay } from './Modal.style';
import { device } from '../../../config/device';
import { Button, SecondaryButton } from '../Button/Button';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 4rem;

  h4 {
    padding: 2.5rem 1rem;
  }

  .button-wrapper {
    width: 100%;
    margin-top: auto;
    padding: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    grid-gap: 2rem;
  }

  @media ${device.mobile} {
    padding: 0.5rem;
    .button-wrapper {
      button {
        padding: 1.5rem 2rem;
        font-size: ${(props) => props.theme.x_small};
      }

      padding: 0;
      grid-gap: 1rem;
    }
  }
`;

const MESSAGE = 'The form contains changes. Do you want to continue?';

Modal.setAppElement('#root');

const title = 'Are you sure you want to leave this page?';

const handleRouteChange = (event: any) => {
  event.preventDefault();
  event.returnValue = title;
  return title;
};

type Props = {
  isActive: boolean;
};

function AskUser({ isActive }: Props) {
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }: any) =>
      isActive && currentLocation.pathname !== nextLocation.pathname,
    [isActive],
  );
  const blocker = useBlocker(shouldBlock);
  const isBlockedState = blocker.state === 'blocked';
  const isProceedingState = blocker.state === 'proceeding';

  useEffect(() => {
    if (isBlockedState && !isActive) {
      window.removeEventListener('beforeunload', handleRouteChange);
      blocker.reset?.();
    } else {
      window.addEventListener('beforeunload', handleRouteChange);
    }

    return () => {
      window.removeEventListener('beforeunload', handleRouteChange);
    };
  }, [blocker, isActive]);

  const handleSubmit = () => blocker.proceed?.();
  const handleCancel = () => blocker.reset?.();

  return (
    <>
      {blocker.state === 'blocked' ? (
        <Modal
          isOpen={isBlockedState || isProceedingState}
          onRequestClose={handleCancel}
          style={{
            overlay: {
              zIndex: 99,
              backgroundColor: 'inherit',
            },
            content: {
              border: 'none',
              backgroundColor: 'inherit',
            },
          }}
        >
          <ModalOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, easings: 'circInOut' }}
          >
            <ModalMidScreen askUser={true}>
              <Container>
                <CloseIcon onClick={handleCancel} />
                <h2>Confirm Action</h2>
                <h4>{MESSAGE}</h4>
                <div className='button-wrapper'>
                  <SecondaryButton onClick={handleCancel}>cancel</SecondaryButton>
                  <Button onClick={handleSubmit}>continue</Button>
                </div>
              </Container>
            </ModalMidScreen>
          </ModalOverlay>
        </Modal>
      ) : null}
    </>
  );
}

export default AskUser;

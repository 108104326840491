import { useQuery } from 'react-query';

import { useUserConfiguration } from 'config/themes';
import { getInfoAndBrowserData } from 'helpers/services/services.utils';

function useInfoAndData({ query, list, object, filters, enabled = true }: any) {
  const { softoneTopLimit } = useUserConfiguration();
  return useQuery(
    ['infoAndData', { query, list, object, filters, softoneTopLimit }],
    getInfoAndBrowserData,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    },
  );
}

export { useInfoAndData };

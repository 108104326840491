import { BsCalendar3 } from 'react-icons/bs';
import { HiOutlineViewGridAdd } from 'react-icons/hi';
import { HiOutlineQueueList } from 'react-icons/hi2';
import styled from 'styled-components';

import { HR } from 'components/Groups/Group.style';
import NavLinkLabel from 'components/Navbar/NavLinkLabel';
import { Button } from 'components/shared/Button/Button';
import { device } from 'config/device';
import { normalizeGreek } from 'helpers/helpers';
import { useNavigation } from 'hooks/useNavigation';

import { Loading } from '../../components/shared/Loading';

const FlexContainer = styled.ul`
  margin: 0 2rem;
  padding: 2.5rem 0;
  overflow: hidden;
  list-style: none;
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  @media ${device.tablet} {
    grid-column-gap: 1.5rem;
    grid-row-gap: 0.5rem;
    h3 {
      font-size: ${(props) => props.theme.medium};
    }
    button {
      font-size: ${(props) => props.theme.x_small};
    }
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
  .menu {
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 4rem 4rem 2.5rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: ${(props) => props.theme.shadow[3]};
    background: ${(props) => `${props.theme.special}1A`};
    @media ${device.tablet} {
      padding: 2rem;
    }
  }
  button {
    cursor: pointer;
    flex: 1;
    width: max-content;
    padding: 1.5rem;
    border-radius: 10px;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
`;

type HomeMenuProps = {
  objects: any[];
};

export function HomeMenu({ objects }: HomeMenuProps) {
  const filterdObjects = objects.filter((item) => {
    if (!item.MENUOPTIONS) return false;
    return item.MENUOPTIONS.some((m: any) => m.DISPLAYONDASHBOARD);
  });
  if (!objects.length) return <Loading />;
  return (
    <FlexContainer>
      {filterdObjects.map((item: any) => {
        return (
          <li key={item.OBJECTID}>
            <h3 style={{ padding: '1rem' }}>{item.OBJECTNAME}</h3>
            <HR />
            <div className='menu'>
              {item.MENUOPTIONS.map((option: any, index: number) => {
                if (!option.DISPLAYONDASHBOARD) return null;
                return <ButtonLink key={index} object={item} option={option} />;
              })}
            </div>
          </li>
        );
      })}
    </FlexContainer>
  );
}

type ButtonLinkProps = {
  object: any;
  option: any;
};

function ButtonLink({ object, option }: ButtonLinkProps) {
  const navigate = useNavigation(object.OBJECTPATH);
  return (
    <Button data-cy={`Button-${option.TITLE}`} onClick={() => navigate(option)}>
      {(() => {
        if (option.TYPE === 'CREATE') {
          return <HiOutlineViewGridAdd />;
        }
        if (option.TYPE === 'LIST') {
          return <HiOutlineQueueList />;
        }
        if (option.TYPE === 'CALENDAR') {
          return <BsCalendar3 />;
        }
        return null;
      })()}
      <NavLinkLabel
        object={object}
        option={{
          ...option,
          TITLE: normalizeGreek(option.TITLE),
        }}
      />
    </Button>
  );
}

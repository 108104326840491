import format from 'date-fns/format';

import { SIGNATURE_PREFIX, SOFTONE_FILES } from 'config/constants';
import { groupBy, isObjEmpty } from 'helpers/helpers';

function getConfigByID(id: any, config: any) {
  return config.filter((configItem: any) => id === configItem.OBJECTID);
}
function getConfigByPath(path: any, config: any) {
  return config.filter((configItem: any) => path === configItem.PATH);
}
function getOjbectByID(id: any, objects: any) {
  return objects.find((object: any) => id === object.OBJECTID);
}
function getObjectByPath(path: any, objects: any) {
  return objects.find((objectItem: any) => path === objectItem.OBJECTPATH);
}

function checkIfReady(context: any) {
  // Return true if every detail satisfy the statement, else false
  return context.details.every((detail: any) => detail.ready === true);
}

function checkIfAbleToSave(context: any) {
  // Return true if every detail satisfy the statement, else false
  return Boolean(context.details.every((detail: any) => detail.ready === true));
}

function skipPdf(context: any) {
  if (context.object?.TEMPLATES?.length > 0) return false;
  return true;
}
function skipFiles(context: any) {
  if (!context.object.OBJECTFILEPATH) return true;
  if (context.object.OBJECTFILEPATH === '') return true;
  return false;
}
function skipSignature(context: any) {
  if (!context.object.OBJECTSIGNATUREPATH) return true;
  if (context.object.OBJECTSIGNATUREPATH === '') return true;
  if (!context.signature) return true;
  return false;
}

function objectsAreEqual(obj1: any, obj2: any) {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  for (const key of obj1Keys) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!objectsAreEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}

function arraysAreEqual(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const val1 = arr1[i];
    const val2 = arr2[i];

    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!arraysAreEqual(val1, val2)) {
        return false;
      }
    } else if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!objectsAreEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}

function sameFiles(context: any) {
  return arraysAreEqual(
    context.files,
    JSON.parse(sessionStorage.getItem('starterFiles') as any) || [],
  );
}
function hasImgSignature(context: any) {
  if (!context.signature) return false;
  //return context.signature.resignature === SOFTONE_DATABASE;
  /* If returns true saves the signature to S1
  If returns false saves the signature to Artie
  The S1 upload is implemented using the SaveButton */
  return false;
}
function getObjectByParams(objectPath = '', optionPath = '', objects: any) {
  const object = objects.find((objectItem: any) => objectPath === objectItem.OBJECTPATH);
  if (isObjEmpty(object)) return {};
  const optionMatch = object.MENUOPTIONS.find(
    (option: any) => option.PATH === optionPath,
  );

  return {
    ...object,
    OBJSOFTONEFILTERS: optionMatch?.OBJSOFTONEFILTERS ?? object.OBJSOFTONEFILTERS,
    OBJSOFTONEFORM: optionMatch?.OBJSOFTONEFORM ?? object.OBJSOFTONEFORM,
    OBJSOFTONELIST: optionMatch?.OBJSOFTONELIST ?? object.OBJSOFTONELIST,
    EDIT: optionMatch?.EDIT ?? 0,
    TITLE: optionMatch?.TITLE ?? 0,
    PRINTTEMPLATE: optionMatch?.PRINTTEMPLATE ?? 0,
    HASMENUOPTION: Boolean(optionMatch || false),
  };
}

function getDisplayFields(displayFieldsString: any, fieldEditor: any) {
  const displaysStringsArray = displayFieldsString.split(',');
  return displaysStringsArray.map(
    (displayString: any) => `${fieldEditor}_${displayString}`,
  );
}
function getFieldValue(id: any) {
  const arr = id.split('_');
  return arr[arr.length - 1];
}
function getDisplayValue(item: any, displayObject: any) {
  const displayKeys = getDisplayFields(item.FIELDDISPLAY, item.FIELDEDITOR);
  const displayValues = displayKeys.map((displayKey: any) => displayObject[displayKey]);
  return displayValues.filter((item: any) => Boolean(item)).join(' | ');
}
function getListDisplayValue(displayFieldsString = '', displayObject: any) {
  const displayValues = displayFieldsString
    .split(',')
    .map((displayKey) => displayObject[displayKey]);
  return displayValues.filter((item) => Boolean(item)).join('|');
}

function submitAfterUpdateCreateData(item: any, createdObjectId: any) {
  const object = item.configItem.FIELDNAME.split('.')[0];
  const table = item.configItem.FIELDNAME.split('.')[1];
  return {
    [object]: [{ [table]: createdObjectId }],
  };
}

export function isSignatureFile(name: any) {
  return String(name).includes(SIGNATURE_PREFIX);
}
function getValidGroupData(files: any) {
  // keep only signature files that need to use setFiles and not setSignature
  return files.filter((file: any) => {
    if (!isSignatureFile(file.name)) return true;
    return file.resignature === SOFTONE_FILES;
  });
}
function submitFilesData(
  files = [],
  deletedFiles = [],
  { basePath, signaturePath }: any,
) {
  const groupByFilesKey = groupBy('softoneSubmitKey');
  const deleteGroups = groupByFilesKey(deletedFiles);
  const tablesWithDeletedFiles = Object.keys(deleteGroups);
  const currentFileGroups = groupByFilesKey(files);
  const currentFilesTables = Object.keys(currentFileGroups);

  // when we dont have new files for a table but we have
  // deleted files for it (case that table had files but now all are deleted)
  // we need to explicit tell that the table has now no files

  const tablesWithAllFilesDeleted = tablesWithDeletedFiles.filter((tableToDelete) => {
    const match = currentFilesTables.find(
      (tableWithNew) => tableWithNew === tableToDelete,
    );
    if (match) return false;
    return true;
  });
  const dataForTablesWithAllFilesDeleted = tablesWithAllFilesDeleted.reduce(
    (prev, cur) => ({ ...prev, [cur]: {} }),
    {},
  );

  const fileData = currentFilesTables.reduce((prev, cur) => {
    const validGroupData = getValidGroupData(currentFileGroups[cur]);
    const curGroupData = validGroupData.map((curFile: any, index: any) => {
      const path = curFile.basePath
        ? curFile.basePath
        : isSignatureFile(curFile.name)
          ? signaturePath
          : basePath;
      return {
        LNUM: index + 1,
        LINENUM: index + 1,
        NAME: curFile.name,
        SOFNAME: curFile.serverPath,
        CODE: path,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        CRTDATE: format(new Date(), 'yyy/MM/dd HH:mm:ss'),
      };
    });
    return { ...prev, [cur]: curGroupData };
  }, {});
  return { ...fileData, ...dataForTablesWithAllFilesDeleted };
}

export {
  skipPdf,
  skipFiles,
  skipSignature,
  sameFiles,
  checkIfReady,
  getFieldValue,
  getConfigByID,
  getOjbectByID,
  hasImgSignature,
  getObjectByPath,
  getDisplayValue,
  getConfigByPath,
  getDisplayFields,
  getObjectByParams,
  getListDisplayValue,
  submitFilesData,
  submitAfterUpdateCreateData,
  checkIfAbleToSave,
};

import { useMachine } from '@xstate/react';
import { useTheme } from 'styled-components';

import { CustomInput } from 'components/shared/Input/Input.style';
import { EOPYY_API } from 'config/API';
import { EopyyMachine } from 'machines/EopyyMachine/EopyyMachine';

type Props = {
  value: string;
  onChange: (e: any) => void;
  storeId: string;
  eopyyInfo: any;
  handleUpdate: any;
  handleSuccess: (e: any) => void;
};

function EopyyDetail({
  value,
  onChange,
  storeId,
  eopyyInfo,
  handleUpdate,
  handleSuccess,
}: Props) {
  const theme = useTheme();
  const [state, send] = useMachine(EopyyMachine, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    context: { ...EopyyMachine.context, EOPYY_API, eopyyCode: value },
    actions: {
      handleUpdate: ({ eopyyCode }: any) => {
        return handleUpdate(eopyyCode);
      },
      handleSuccess: (_: any, e: any) => {
        const Icd10 = e.data ? e.data.Icd10 : e.Icd10;
        const Icd10Descr = e.data ? e.data.Icd10Descr : e.Icd10Descr;
        const Intangible = e.data ? e.data.Intagible : e.Intagible;
        return handleSuccess({
          storeId,
          variants: { Icd10, Icd10Descr, Intangible },
        });
      },
    },
  });

  function renderBorderColor() {
    if (state.matches('success')) {
      return theme.green;
    }
    if (state.matches('error')) {
      return theme.error;
    }
  }
  const handleFocus = () => {
    send({ type: 'RETRY' });
  };
  const handleOnBlur = () => {
    const found = eopyyInfo.eoppyDetailItemCodes.find((item: any) => item.id === storeId);
    send({
      type: 'CHECK_CODE',
      eoppyItemCode: found?.code,
      eopyyPatientCode: eopyyInfo.eopyyPatientCode,
    });
  };

  function handleChange(e: any) {
    send({ type: 'UPDATE_CODE', code: e.target.value });
    onChange(e);
  }

  return (
    <CustomInput
      style={{
        width: '26ch',
        boxShadow: 'none',
        padding: 0,
        color: 'inherit',
        backgroundColor: 'inherit',
        borderBottomColor: renderBorderColor(),
      }}
      value={value}
      onFocus={handleFocus}
      onChange={handleChange}
      onBlur={handleOnBlur}
    />
  );
}

export default EopyyDetail;

import { createMachine } from 'xstate';

export const SignatureMachine = createMachine({
  id: 'signatureMachine',
  context: {
    sendToForm: null,
  },
  initial: 'idle',
  states: {
    idle: {
      on: {
        SAVE: {
          target: 'saving',
          actions: 'save',
        },
      },
    },
    saving: {
      on: { EDIT: 'idle' },
    },
  },
  on: {
    CLEAR: {
      target: 'idle',
      actions: 'clear',
    },
  },
});
